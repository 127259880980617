.App {
  text-align: center;
}

.App-value {
  font-size: 40vmin;
}

.App-button {
  font-size: 5vmin;
  cursor: pointer;
  color: hsl(220, 13%, 18%);
  font-weight: bold;
  padding: 10px 5vmin;
  border-radius: 12px;
  background-color: hsl(220, 5%, 85%);
}

.App-button:hover {
  color: hsl(220, 13%, 10%);
  background-color: hsl(220, 60%, 98%);
}

.App-button:active {
  color: hsl(220, 13%, 10%);
  background-color: hsl(220, 5%, 80%);
}

.App-header {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-spacer {
  height: 10vmin;
  width: 1vmin;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
